// import logo from '../../assets/logo1-full-white.svg';
import logo from '../../assets/MMK10.png';
import blankWhite from '../../assets/blank_white.png';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const InitialPage = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [a, setA] = useState(false);
    const [b, setB] = useState(false);
    const [c, setC] = useState(false);

    useEffect(() => {
        window.scroll(0, 0);
        let interval = setInterval(() => {
            if (a === false) {
                setA(prevValueA => {
                    if (prevValueA === true) {
                        setB(prevValueB => {
                            if (prevValueB === true) {
                                setC(prevValueC => {
                                    if (prevValueC === true) {
                                        setA(prevValueA => false);
                                        setB(prevValueB => false);
                                        setC(prevValueC => false);
                                    }
                                    return prevValueC === false ? true : false
                                });
                            }
                            return true
                        });
                    }
                    return prevValueA === false ? true : false
                });
            } else if (b === false) {
                setB(true);
            } else if (c === false) {
                setC(true);
            } else if (a === true && b === true && c === true) {
                setA(false)
                setB(false)
                setC(false)
            }
        }, 555)
        setTimeout(() => {
            navigate('/login', { replace: true })
        }, 4152.4);
        return () => {
            clearInterval(interval);
        }
    }, [])
    return (
        <>
            <div>
                <div style={{ position: 'relative', display: 'flex', justifyContent: 'center' }}>
                    <div>
                        <img src={logo} width={'255px'} alt='' className='mainLogo' />
                        <img src={blankWhite} width={'255px'} alt='' className='mainLogoDuplicate' style={{ position: 'absolute', 'top': 0, 'left': 0 }} />
                    </div>
                </div>
            </div>
            <div style={{
                display: 'grid', gridTemplateColumns: '12em 5em',
                position: 'relative', justifyContent: 'left', gap: '2.5em'
            }}><div style={{ display: "inline-grid", fontSize: '27px' }}>LOADING</div><div className="loadingDots"> {a && '.'}{b && '.'}{c && '.'}</div></div>
        </>
    )
}

export default InitialPage;