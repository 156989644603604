import React, { useEffect, useState, useRef } from "react";
import { getFirestore, doc, setDoc, getDoc, updateDoc, arrayUnion } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import debounce from '../../../utility/login_debounce';

const db = getFirestore();
const auth = getAuth();

const Comments = ({ postId }) => {
    const [reactions, setReactions] = useState({ likes: 0, hearts: 0, claps: 0 });
    const [comments, setComments] = useState([]);
    const [commentText, setCommentText] = useState("");
    let debouncedButton = useRef(debounce(() => { }, 255));

    // Fetch post data from Firestore
    useEffect(() => {
        const fetchPost = async () => {
            const postRef = doc(db, "posts", postId);
            const postSnap = await getDoc(postRef);
            if (postSnap.exists()) {
                const data = postSnap.data();
                setReactions(data.reactions || { likes: 0, hearts: 0, claps: 0 });
                setComments(data.comments || []);
            } else {
                // console.error("Post not found!");
            }
        };
        fetchPost();
    }, [postId]);

    // Handle Reactions
    const handleReaction = async (type) => {
        const postRef = doc(db, "posts", postId);
        const newReactions = { ...reactions, [type]: reactions[type] + 1 };
        await updateDoc(postRef, { reactions: newReactions });
        setReactions(newReactions);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        debouncedButton.current = debounce(() => { handleCommentSubmit() }, 255, debouncedButton.current());
        if (typeof debouncedButton.current === 'function') {
            debouncedButton.current();
        }
    }

    // Handle Comment Submission
    const handleCommentSubmit = async () => {
        if (!auth.currentUser) {
            alert("Please log in to comment!");
            return;
        }

        const user = auth.currentUser;
        const userRef = doc(db, "users", user.uid);
        const userSnap = await getDoc(userRef);

        let username = userSnap.exists() ? userSnap.data().username : "Anonymous";

        const newComment = {
            id: Date.now(),
            text: commentText,
            user: user.email,
            likes: 0,
            username: user.displayName || username
        };

        const postRef = doc(db, "posts", postId);
        const postSnap = await getDoc(postRef);

        if (!postSnap.exists()) {
            console.error("Post not found, creating a new post entry...");
            await setDoc(postRef, { comments: [newComment], reactions: { likes: 0, hearts: 0, claps: 0 } });
        } else {
            await updateDoc(postRef, {
                comments: arrayUnion(newComment),
            });
        }

        setComments((prev) => [...prev, newComment].sort((a, b) => b.likes - a.likes));
        setCommentText("");
    };

    const handleLikeComment = async (commentId) => {
        if (!auth.currentUser) {
            alert("Please log in to like a comment!");
            return;
        }

        const user = auth.currentUser;
        const postRef = doc(db, "posts", postId);
        const postSnap = await getDoc(postRef);

        if (postSnap.exists()) {
            let updatedComments = postSnap.data().comments.map((comment) => {
                if (comment.id === commentId) {
                    let newLikes = comment.likes || 0;
                    let newLikedBy = comment.likedBy ? [...comment.likedBy] : [];

                    if (newLikedBy.includes(user.uid)) {
                        // &#x2705; Unlike comment (Remove like)
                        newLikes -= 1;
                        newLikedBy = newLikedBy.filter(uid => uid !== user.uid);
                    } else {
                        // &#x2705; Like comment (Add like)
                        newLikes += 1;
                        newLikedBy.push(user.uid);
                    }

                    return {
                        ...comment,
                        likes: newLikes,
                        likedBy: newLikedBy,
                    };
                }
                return comment;
            });

            // Sort comments by likes (highest first)
            updatedComments = updatedComments.sort((a, b) => b.likes - a.likes);

            await updateDoc(postRef, { comments: updatedComments });
            setComments(updatedComments);
        }
    };


    return (
        <div className="container mt-4">
            {/* Reactions Section */}
            <div className="card shadow-sm p-3">
                <h4 className="text-left">React to this Post</h4>
                <div className="d-flex justify-content-left gap-3">
                    <button className="btn btn-outline-primary" onClick={() => handleReaction("likes")}>
                        👍 {reactions.likes}
                    </button>
                    <button className="btn btn-outline-danger" onClick={() => handleReaction("hearts")}>
                        &#x2764;&#xFE0F; {reactions.hearts}
                    </button>
                    <button className="btn btn-outline-warning" onClick={() => handleReaction("claps")}>
                        👏 {reactions.claps}
                    </button>
                </div>
            </div>

            {/* Comments Section */}
            <div className="card mt-3 shadow-sm p-3">
                <h4 className="text-center">Comments</h4>
                <form className="mb-3" onSubmit={onSubmit}>
                    <div className="input-group">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Write a comment..."
                            value={commentText}
                            onChange={(e) => setCommentText(e.target.value)}
                            required
                            maxLength={1786}
                        />
                        <button className="btn btn-primary" type="submit">
                            Comment
                        </button>
                    </div>
                </form>

                {/* Display Comments */}
                <ul className="list-group" style={{ marginBottom: '15px' }}>
                    {comments.map((comment) => (
                        <li key={comment.id} className="list-group-item d-flex justify-content-between align-items-center">
                            <div>
                                <strong>{comment.username}</strong>: {comment.text}
                            </div>
                            <button
                                className="btn btn-sm btn-outline-success"
                                onClick={() => handleLikeComment(comment.id)}
                            >
                                👍 {comment.likes}
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default Comments;
