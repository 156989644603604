import img1 from '../../assets/mmk-favicon-design.png';

const NotFound = () => {
    return <>
        <img src={img1} style={{ height: '75px', position: 'absolute', top: '15px', right: '15px', borderRadius: '55px' }}></img>
        <p><h2>Not Found!</h2></p> <p><h4>Please <a href='/login'>click here</a> to redirect to login.</h4>
        </p >
    </>
}

export default NotFound;