import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import { useState, useRef, useEffect, SetStateAction } from 'react';
import debounce from '../../utility/login_debounce';
import { JSX } from 'react/jsx-runtime';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useLocation, useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword, signOut, sendEmailVerification } from "firebase/auth";
import { collection, query, where, getDocs, setDoc, doc } from "firebase/firestore";
import { useFirebase } from '../auth/context';
import img1 from '../../assets/mmk-favicon-design.png';


const initState = {
    email: '',
    name: '',
    password: ''
}

const RegistrationPage = () => {
    const [formData, setFormData] = useState(initState);
    const [emailStatus, setEmailStatus] = useState(0);
    const [usernameStatus, setUserNameStatus] = useState(0);
    const [passwordStatus, setPasswordStatus] = useState(0);
    const [submittedOnce, setSubmittedOnce] = useState(false);
    const [tabbedCount, setTabbedCount] = useState(0);

    const [formError, setFormError] = useState([<></>]);
    const refSubmitButton = useRef(<input />);
    const refFirstTab = useRef(<input />);
    const refUserNameTab = useRef(<input />);
    const refPassTab = useRef(<input />);

    const [showPassword, setShowPassword] = useState(true);

    const navigate = useNavigate();
    const location = useLocation();

    const { auth, db } = useFirebase();

    let debouncedForm = useRef(debounce(() => { }, 255)) as any;

    let debouncedButton = useRef(debounce(() => { }, 255)) as any;

    useEffect(() => {
        window.scroll(0, 0);
        onSlide1iN();
        if (location?.state?.email) {
            setFormData({ ...formData, email: location?.state?.email || '' });
            findEmailErrors(location?.state?.email).then(emailErrors => {
                if (emailErrors) {
                    setTimeout(() => (refFirstTab?.current as any)?.focus(), 1555);
                } else {
                    setTimeout(() => (refUserNameTab?.current as any)?.focus(), 1555);
                }
            }).catch(e => console.log(e));
        } else {
            (refFirstTab?.current as any)?.focus();
        }
    }, [])


    useEffect(() => {
        if (submittedOnce) {

            if (formError[0]?.props?.children?.includes('Email')) {
                const userNameBox = document.querySelector('#email');
                userNameBox?.animate(
                    [{ transform: "translate3d(0, 0, 0)" }, { transform: "translate3d(5px, 0, 5px)" }],
                    {
                        duration: 275,
                        iterations: 2,
                        direction: "alternate",
                    }
                );
                (refFirstTab.current as any)?.focus();
            } else if (formError[0]?.props?.children?.includes('Username')) {
                const userNameBox = document.querySelector('#username');
                userNameBox?.animate(
                    [{ transform: "translate3d(0, 0, 0)" }, { transform: "translate3d(5px, 0, 5px)" }],
                    {
                        duration: 255,
                        iterations: 2,
                        direction: "alternate",
                    }
                );
                (refUserNameTab.current as any)?.focus();
            } else if (formError[0]?.props?.children?.includes('Password')) {
                const passBox = document.querySelector('#password');
                passBox?.animate(
                    [{ transform: "translate3d(0, 0, 0)" }, { transform: "translate3d(5px, 0, 5px)" }],
                    {
                        duration: 255,
                        iterations: 2,
                        direction: "alternate",
                    }
                );
                (refPassTab.current as any)?.focus();
            }
        }
    }, [formError])

    useEffect(() => {
        const passElement = document.getElementById('password');
        (passElement as HTMLInputElement).type = (passElement as HTMLInputElement).type === "password" ? 'text' : 'password';
    }, [showPassword])

    const onChangeForm = ({ name, value }: { name: string, value: string }) => {
        if (name === 'email' && tabbedCount > 0) {
            value = value.replace(/[^a-zA-Z0-9_.@]/, '').trim();
            debouncedForm.current = debounce(() => { debouncedemailFunction(value) }, 255, debouncedForm.current());
            if (typeof debouncedForm.current === 'function') {
                debouncedForm.current();
            }
        } else if (name === 'name' && tabbedCount > 1) {
            value = value.replace(/[^a-zA-Z\s]/, '');
            const errors = findUserNameErrors(value)
            if (errors?.length) {
                submittedOnce && setUserNameStatus(-1);
                submittedOnce && setFormError(errors);
            } else {
                setFormError([<></>])
                setUserNameStatus(1);
            }
        } else if (name === 'password' && tabbedCount > 2) {
            value = value.trim();
            const errors = findPasswordErrors(value)
            if (errors?.length) {
                submittedOnce && setPasswordStatus(-1);
                submittedOnce && setFormError(errors);
            } else {
                setFormError([<></>])
                setPasswordStatus(1);
            }
        }
        setFormData({ ...formData, [name]: value });
    }

    const debouncedemailFunction = async (value: any) => {
        await findEmailErrors(value);
    }

    const OnTabbedOnce = async () => {
        const errorsEmail = await findEmailErrors();
        const errorsName = findUserNameErrors();
        const errorsPass = findPasswordErrors();
        setTabbedCount(tabbedCount + 1);
        let errorBool = false;
        if (tabbedCount > -1 && errorsEmail) {
            onClickAnimation();
            errorBool = true;
        } else if (tabbedCount > 0 && errorsName.length) {
            onClickAnimation();
            errorBool = true;
        } else if (tabbedCount > 1 && errorsPass?.length) {
            onClickAnimation();
            errorBool = true;
        }
        if (errorBool && tabbedCount > 0 && errorsName?.length) {
            setUserNameStatus(-1);
            setFormError(errorsName)
        }
        if (errorBool && tabbedCount > 1 && errorsPass?.length) {
            setPasswordStatus(-1);
            setFormError(errorsPass)
        }
        if (!errorBool) {
            setFormError([<></>]);
        }
    }


    const checkIfUserExists = async (email: String) => {
        try {
            const usersRef = collection(db, "users"); // Reference to users collection
            const q = query(usersRef, where("email", "==", email)); // Query for email match
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                // console.log("User exists:", querySnapshot.docs[0].data());
                return true; // User exists
            } else {
                // console.log("User does not exist");
                return false; // User does not exist
            }
        } catch (error) {
            console.error("Error checking user existence:", error);
            return false;
        }
    };

    const findEmailErrors = async (value = formData.email) => {
        let errors: SetStateAction<JSX.Element[]> = [];
        let emailCheck = await checkIfUserExists(value);
        if (!value) {
            errors = [<div style={{ color: 'red' }}>Please Meet Following Email Requirements:</div>];
            errors = [...errors, <div style={{ whiteSpace: 'pre' }}>&#8226; Email is Required</div>]
        } // eslint-disable-next-line 
        else if (!value.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)) {
            errors = [<div style={{ color: 'red' }}>Please Meet Following Email Requirements:</div>];
            errors = [...errors, <div style={{ whiteSpace: 'pre' }}>&#8226; Incorrect Email Format!</div>]
        }
        else if (emailCheck === true) {
            errors = [<div style={{ color: 'red' }}>Please Meet Following Email Requirements:</div>];
            errors = [...errors, <div style={{ whiteSpace: 'pre' }}>&#8226; Email Already Exists!</div>]
        }
        else {
            errors = []
            setEmailStatus(1)
            return;
        }
        setEmailStatus(-1);
        setFormError(errors);
        return errors
    }

    const findPasswordErrors = (value = formData.password) => {
        let errors: SetStateAction<JSX.Element[]> = [];
        errors = !errors[0]?.props?.children?.includes('Password Requirements') ? [...errors, <div style={{ color: 'red' }}>Please Meet Following Password Requirements:</div>] : errors;
        if (!submittedOnce && value.length < 8) {
            errors = [...errors, <div style={{ whiteSpace: 'pre' }}>&#8226; Minimum length of 8 characters</div>]
            return errors;
        } else {
            if (value.length < 8 && submittedOnce) {
                errors = [...errors, <div style={{ whiteSpace: 'pre' }}>&#8226; Minimum length of 8 characters</div>]
            }
            if (!value.match(/[a-zA-Z]+/)) {
                errors = [...errors, <div style={{ whiteSpace: 'pre' }}>&#8226; Contains at least 1 letter</div>]
            }
            if (!value.match(/[0-9]+/)) {
                errors = [...errors, <div style={{ whiteSpace: 'pre' }}>&#8226; Contains at least 1 number</div>]
            }
            if (!value.match(/[^0-9a-zA-z\s]+/)) {
                errors = [...errors, <div style={{ whiteSpace: 'pre' }}>&#8226; Contains at least 1 special character</div>]
            }
            return errors?.length === 1 ? [] : errors;
        }
    }

    const findUserNameErrors = (value = formData.name) => {
        let errors: SetStateAction<JSX.Element[]> = [];
        if (!value) {
            errors = [<div style={{ color: 'red' }}>Please Meet Following Username Requirements:</div>];
            errors = [...errors, <div style={{ whiteSpace: 'pre' }}>&#8226; Name is Required</div>]
        } else {
            errors = []
        }
        return errors
    }

    const onClickAnimation = () => {
        const box = document.querySelector('.errorBoxInner') as any;
        box?.animate(
            { transform: 'translateX(10px)' },
            {
                duration: 100
            }
        );
    }


    const onSlide1iN = () => {
        const box = document.querySelector('.errorBoxAnimated') as any;
        const box2 = document.querySelector('.blinkRegister') as any;
        const box3 = document.querySelector('.bounceRegister') as any;

        box?.animate(
            { transform: 'translateX(100px)' },
            {
                duration: 50
            }
        )

        setTimeout(() => box2?.animate(
            [{ transform: "translateY(0)" }, { transform: "translateY(-5px)" }],
            {
                duration: 100,
                easing: "linear",
            }
        ).finished.then(box3.animate(
            [{ transform: "translateY(0)" }, { transform: "translateY(-5px)" }],
            {
                duration: 100,
                easing: "linear",
            }
        ), 524))
    }

    const debouncedSubmitFunction = async () => {
        try {
            // &#x0031;&#xFE0F;&#x20E3; Create User in Firebase Authentication
            const userCredential = await createUserWithEmailAndPassword(auth, formData.email, formData.password);
            const user = userCredential.user;
            // console.log("User registered:", user);

            // &#x0032;&#xFE0F;&#x20E3; Send Email Verification
            await sendEmailVerification(user);
            // console.log("Verification email sent!");

            // &#x0033;&#xFE0F;&#x20E3; Store Additional User Data in Firestore
            await setDoc(doc(db, "users", user.uid), {
                username: formData.name,
                email: formData.email,
                emailVerified: false, // Initially false
                createdAt: new Date().toISOString(),
            });

            // &#x0034;&#xFE0F;&#x20E3; Sign the user out so they verify first
            await signOut(auth);

            // &#x0035;&#xFE0F;&#x20E3; Redirect to verification page
            navigate('/verify-email', { state: { email: formData.email } });

        } catch (error: any) {
            console.error("Registration Error:", error);

            let errorMessage = "Network Error. Please Try Again!";
            if (error.code) {
                switch (error.code) {
                    case "auth/email-already-in-use":
                        errorMessage = "This email is already in use.";
                        break;
                    case "auth/weak-password":
                        errorMessage = "Password should be at least 6 characters.";
                        break;
                    case "auth/invalid-email":
                        errorMessage = "Invalid email format.";
                        break;
                    default:
                        errorMessage = "An unexpected error occurred.";
                        break;
                }
            }

            setFormError([...formError, <>{errorMessage}</>]);
        }

        if (refSubmitButton?.current) {
            (refSubmitButton?.current as any)?.blur();
        }
    };


    const OnSubmit = async () => {
        setSubmittedOnce(true);
        let errorsUserName = findUserNameErrors();
        let errorsPass = findPasswordErrors();
        let errorsEmail = await findEmailErrors();
        if (errorsEmail) {
            onClickAnimation();
            return;
        } else if (errorsUserName.length > 0) {
            setFormError(errorsUserName);
            setUserNameStatus(-1);
            onClickAnimation();
            return;
        } else if (errorsPass?.length) {
            setFormError(errorsPass);
            setPasswordStatus(-1);
            onClickAnimation();
            return;
        } else {
            setFormError([<></>]);
        }
        setUserNameStatus(!errorsUserName.length ? 1 : -1)
        setPasswordStatus(!errorsPass.length ? 1 : -1);
        debouncedButton.current = debounce(() => { debouncedSubmitFunction() }, 255, debouncedButton.current());
        if (typeof debouncedButton.current === 'function') {
            debouncedButton.current();
        }
    }

    return (<>
        {/* <Navbar expand="lg" bg="white" variant="light" fixed="top">
            <div style={{ display: 'block', height: '55px', background: 'white' }}>
                <Navbar.Brand style={{ position: 'absolute', right: '0px', marginRight: '5px' }}>
                    <img src={logo} width="77" alt="Blog Logo" />
                </Navbar.Brand>
            </div>
        </Navbar > */}
        <img src={img1} alt='' onClick={() => navigate('/')} style={{ height: '75px', position: 'absolute', top: '15px', right: '15px', borderRadius: '55px' }}></img>
        <div className='errorBoxAnimated' onKeyDown={(e) => { e.key === 'Enter' && OnSubmit(); e.key === 'Tab' && tabbedCount < 7 && OnTabbedOnce() }} >
            <h1>Register <span className='blinkRegister'>Here</span><div className='bounceRegister'>!</div></h1>
            <div className="mainGrid">
                <label >e-mail</label>
                <input type='text' maxLength={45} onBlur={() => OnTabbedOnce()} ref={refFirstTab as any} tabIndex={1} id={'email'} className="inputText" placeholder="Please enter your e-mail" name="email" value={formData.email} onChange={(e: any) => onChangeForm(e.target)} />
                {emailStatus === 1 ? <CheckCircleRoundedIcon style={{ alignSelf: 'center' }} /> : emailStatus === -1 ? <ErrorRoundedIcon style={{ alignSelf: 'center' }} /> : <div style={{ visibility: 'hidden' }}></div>}
                <label >Full Name</label>
                <input type='text' maxLength={45} ref={refUserNameTab as any} tabIndex={2} id={'username'} className="inputText" placeholder="Please enter your name" name="name" value={formData.name} onChange={(e: any) => onChangeForm(e.target)} />
                {usernameStatus === 1 ? <CheckCircleRoundedIcon style={{ alignSelf: 'center' }} /> : usernameStatus === -1 ? <ErrorRoundedIcon style={{ alignSelf: 'center' }} /> : <div style={{ visibility: 'hidden' }}></div>}
                <label >Password</label>
                <div style={{ position: 'relative', display: 'grid' }}>
                    <input type='text' maxLength={45} ref={refPassTab as any} tabIndex={3} className="inputText" id={'password'} placeholder="Please enter your password" name="password" value={formData.password} onChange={(e: any) => onChangeForm(e.target)} style={{ paddingRight: '27.5px' }} />
                    <div style={{ position: 'absolute', right: '5px', top: '-5px' }}>
                        {!showPassword ? <VisibilityIcon onClick={() => setShowPassword(!showPassword)} /> : <VisibilityOffIcon onClick={() => setShowPassword(!showPassword)} />}
                    </div>
                </div>
                {passwordStatus === 1 ? <CheckCircleRoundedIcon style={{ alignSelf: 'center' }} /> : passwordStatus === -1 ? <ErrorRoundedIcon style={{ alignSelf: 'center' }} /> : <div style={{ visibility: 'hidden' }}></div>}
                <div style={{ visibility: 'hidden' }}></div>
                {/* <div>Show Password<input type='checkbox' onChange={() => setShowPassword(!showPassword)} checked={!showPassword} /></div> */}
            </div >
            {passwordStatus === -1 || usernameStatus === -1 || emailStatus === -1 || formError.length > 1 ? <div className='errorBoxInner'>{formError}</div> : <></>}
            <div className="mainGrid" style={{ justifyContent: 'center' }}>
                <input type='button' ref={refSubmitButton as any} tabIndex={4} className="inputButton" onClick={() => OnSubmit()} value="REGISTER" />
                <input type='button' tabIndex={4} className="inputButton inputButtonSecondary" onClick={() => navigate('/login')} value="BACK" />
            </div>
        </div>
    </>
    )
}

export default RegistrationPage;