// src/components/ProtectedRoute.js
import { useState, useEffect } from 'react';
import { Navigate } from "react-router-dom";
import { useFirebase } from "./context";
import logo from '../../assets/MMK10.svg';
import blankWhite from '../../assets/blank_white.png';

const Loader = () => {
    const [a, setA] = useState(false);
    const [b, setB] = useState(false);
    const [c, setC] = useState(false);

    useEffect(() => {
        let interval = setInterval(() => {
            if (a === false) {
                setA(prevValueA => {
                    if (prevValueA === true) {
                        setB(prevValueB => {
                            if (prevValueB === true) {
                                setC(prevValueC => {
                                    if (prevValueC === true) {
                                        setA(prevValueA => false);
                                        setB(prevValueB => false);
                                        setC(prevValueC => false);
                                    }
                                    return prevValueC === false ? true : false
                                });
                            }
                            return true
                        });
                    }
                    return prevValueA === false ? true : false
                });
            } else if (b === false) {
                setB(true);
            } else if (c === false) {
                setC(true);
            } else if (a === true && b === true && c === true) {
                setA(false)
                setB(false)
                setC(false)
            }
        }, 555)
        return () => {
            clearInterval(interval);
        }
    }, [])

    return (
        <>
            <div>
                <div style={{ position: 'relative', display: 'flex', justifyContent: 'center' }}>
                    <div>
                        <img src={logo} alt='' width={'255px'} className='mainLogo' />
                        <img src={blankWhite} alt='' width={'255px'} className='mainLogoDuplicate' style={{ position: 'absolute', 'top': 0, 'left': 0 }} />
                    </div>
                </div>
            </div>
            <div style={{
                display: 'grid', gridTemplateColumns: '12em 5em',
                position: 'relative', justifyContent: 'left', gap: '2.5em'
            }}><div style={{ display: "inline-grid", fontSize: '27px' }}>LOADING</div><div className="loadingDots"> {a && '.'}{b && '.'}{c && '.'}</div></div>
        </>
    )
}


const NavigationRoute = ({ children }) => {
    const { user, loading } = useFirebase();

    if (loading) return <Loader />;
    if (user) return <Navigate to="/welcome" replace />;

    return children;
};

export default NavigationRoute;
