import React from "react";

const MiniLoader = () => {
    return (
        <div className="d-flex justify-content-center align-items-center">
            <div className="spinner-border text-primary" style={{ width: "17px", height: "17px", borderWidth: "4px" }} role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    );
};

export default MiniLoader;
