import React, { useState } from "react";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { Button } from "react-bootstrap";
import img1 from '../../assets/mmk-favicon-design.png';
import { useNavigate } from "react-router";

const auth = getAuth();

export const sendResetEmail = async (email: any) => {
    try {
        await sendPasswordResetEmail(auth, email);
        return { success: true, message: "Password reset email sent!" };
    } catch (error) {
        return { success: false, message: (error as any).message };
    }
};

const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const navigate = useNavigate();

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        const response = await sendResetEmail(email);
        setMessage(response.message);
    };

    return (<>
        {/* <Navbar expand="lg" bg="white" variant="light" fixed="top">
            <div style={{ display: 'block', height: '55px', background: 'white' }}>
                <Navbar.Brand style={{ position: 'absolute', right: '0px', marginRight: '5px' }}>
                    <img src={logo} width="77" alt="Blog Logo" />
                </Navbar.Brand>
            </div>
        </Navbar > */}
        <img src={img1} onClick={() => navigate('/')} style={{ height: '75px', position: 'absolute', top: '15px', right: '15px', borderRadius: '55px' }}></img>
        <div style={{ display: 'block', fontSize: '15px' }}>
            <></>
            <h2>Reset Password</h2>
            {message && <p>{message} Click <a href='/login'>here</a> to go back to <a href='/login'>Login</a>!</p>}
            <form onSubmit={handleSubmit}>
                <p><input
                    className="inputText"
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    style={{ width: '274px' }}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                /></p>
                <p><Button type="submit">Send Reset Email</Button>
                </p>
            </form>
        </div>
    </>
    );
};

export default ForgotPassword;
