import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { getFirestore, doc, setDoc, getDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { app } from "../../auth/firebase";

const db = getFirestore(app);

const SubscriptionModal = ({ show, handleClose }) => {
    const [selectedSections, setSelectedSections] = useState([]);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");

    const sections = [
        "General Articles",
        "Technology/Code",
        "Faith/Religion",
        "Occult/Spirituality",
        "Family",
    ];

    const auth = getAuth();
    const user = auth.currentUser;

    useEffect(() => {
        if (user) {
            fetchUserSubscription();
        }
    }, [user]);

    const fetchUserSubscription = async () => {
        if (!user) return;
        const userRef = doc(db, "subscriptions", user.uid);
        const docSnap = await getDoc(userRef);

        if (docSnap.exists()) {
            setSelectedSections(docSnap.data().preferences || []);
        }
    };

    const handleCheckboxChange = (section) => {
        setSelectedSections((prev) =>
            prev.includes(section)
                ? prev.filter((s) => s !== section)
                : [...prev, section]
        );
    };

    const handleSavePreferences = async () => {
        if (!user) {
            setMessage("You need to log in to subscribe.");
            return;
        }

        setLoading(true);
        const userRef = doc(db, "subscriptions", user.uid);

        try {
            await setDoc(userRef, { preferences: selectedSections }, { merge: true });
            setMessage("Subscription preferences updated successfully!");
        } catch (error) {
            setMessage("Failed to update preferences. Try again.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title><u>Subscribe to the Newsletter</u></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Select the sections you want to subscribe to:</p>
                <Form style={{ boxShadow: '5px 4px darkslateblue' }}>
                    {sections.map((section) => (
                        <Form.Check
                            style={{ paddingBottom: '12px' }}
                            key={section}
                            type="checkbox"
                            label={section}
                            checked={selectedSections.includes(section)}
                            onChange={() => handleCheckboxChange(section)}
                        />
                    ))}
                </Form>
                {message && <p className="text-success mt-2">{message}</p>}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSavePreferences} disabled={loading}>
                    {loading ? "Saving..." : "Save Preferences"}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SubscriptionModal;
