import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import { useState, useRef, useEffect, SetStateAction } from 'react';
import debounce from '../../utility/login_debounce';
import { JSX } from 'react/jsx-runtime';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useNavigate, NavLink } from "react-router-dom";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { collection, query, where, setDoc, getDocs, getDoc, doc } from "firebase/firestore";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { useFirebase } from '../auth/context';
import logoGoogle from "../../assets/web_neutral_rd_na@4x.png";
import img1 from '../../assets/mmk-favicon-design.png';

const initState = {
    email: '',
    password: ''
}

const LoginPage = () => {
    const [formData, setFormData] = useState(initState);
    const [emailStatus, setemailStatus] = useState(0);
    const [passwordStatus, setPasswordStatus] = useState(0);
    const [submittedOnce, setSubmittedOnce] = useState(false);
    const [tabbedCount, setTabbedCount] = useState(0);

    const [formError, setFormError] = useState([<></>]);
    const refSubmitButton = useRef(<input />);
    const refFirstTab = useRef(<input />);
    const refPassTab = useRef(<input />);

    const navigate = useNavigate();

    const [hidePassword, setHidePassword] = useState(true);

    let debouncedForm = useRef(debounce(() => { }, 255)) as any;

    let debouncedButton = useRef(debounce(() => { }, 55)) as any;

    useEffect(() => {
        window.scroll(0, 0);
        (refFirstTab?.current as any).focus();
    }, [])

    const { auth, db } = useFirebase();

    useEffect(() => {
        if (submittedOnce) {
            if (formError[0]?.props?.children?.includes('Email')) {
                const emailBox = document.querySelector('#email');
                emailBox?.animate(
                    [{ transform: "translate3d(0, 0, 0)" }, { transform: "translate3d(7px, 0, 7px)" }],
                    {
                        duration: 255,
                        iterations: 2,
                        direction: "alternate",
                    }
                );
                (refFirstTab.current as any)?.focus();
            } else if (formError[0]?.props?.children?.includes('Password')) {
                const passBox = document.querySelector('#password');
                passBox?.animate(
                    [{ transform: "translate3d(0, 0, 0)" }, { transform: "translate3d(7px, 0, 7px)" }],
                    {
                        duration: 127,
                        iterations: 4,
                        direction: "alternate",
                    }
                );
                (refPassTab.current as any)?.focus();
            }
        }
    }, [formError])

    useEffect(() => {
        const passElement = document.getElementById('password');
        (passElement as HTMLInputElement).type = (passElement as HTMLInputElement).type === "password" ? 'text' : 'password';
    }, [hidePassword])

    const onChangeForm = async ({ name, value }: { name: string, value: string }) => {
        setFormData({ ...formData, [name]: value });
        if (name === 'email' && tabbedCount > 0) {
            // if (typeof debouncedForm === 'function') {
            debouncedForm.current = debounce(() => { console.log('email value', value); debouncedemailFunction(value) }, 255, debouncedForm.current());
            if (typeof debouncedForm.current === 'function') {
                debouncedForm.current();
            }
            // }
        } else if (name === 'password' && tabbedCount > 1) {
            const errors = findPasswordErrors(value)
            if (errors?.length) {
                setPasswordStatus(-1);
                setFormError(errors);
            } else {
                setFormError([<></>])
                setPasswordStatus(0);
            }
        }
    }

    const debouncedemailFunction = async (value: any) => {
        let errors = await findemailErrors(value)
        if (errors.length) {
            setemailStatus(-1);
            setFormError(errors);
        } else {
            setFormError([<></>])
            setemailStatus(1);
        }
    }

    const checkIfUserExists = async (email: String) => {
        try {
            const usersRef = collection(db, "users"); // Reference to users collection
            const q = query(usersRef, where("email", "==", email)); // Query for email match
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                // console.log("User exists:", querySnapshot.docs[0].data());
                return true; // User exists
            } else {
                // console.log("User does not exist");
                return false; // User does not exist
            }
        } catch (error) {
            console.error("Error checking user existence:", error);
            return false;
        }
    };

    const findemailErrors = async (value = formData.email) => {
        let errors: SetStateAction<JSX.Element[]> = [];
        let emailCheck = await checkIfUserExists(value);
        if (!value) {
            errors = [<div style={{ color: 'red' }}>Please Meet Following Email Requirements:</div>];
            errors = [...errors, <div style={{ whiteSpace: 'pre' }}>&#8226; Email is Required</div>]
        } // eslint-disable-next-line 
        else if (!value.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)) {
            errors = [<div style={{ color: 'red' }}>Please Meet Following Email Requirements:</div>];
            errors = [...errors, <div style={{ whiteSpace: 'pre' }}>&#8226; Incorrect Email Format!</div>]
        } else if (emailCheck !== true) {
            errors = [<div style={{ color: 'red' }}>Please Meet Following Email Requirements:</div>];
            errors = [...errors, <div style={{ whiteSpace: 'pre' }}>&#8226; Email Does Not Exist! Please Register.</div>]
        } else {
            errors = []
        }
        return errors
    }

    const findPasswordErrors = (value = formData.password) => {
        let errors: SetStateAction<JSX.Element[]> = [];
        errors = !errors[0]?.props?.children?.includes('Password Requirements') ? [...errors, <div style={{ color: 'red' }}>Please Meet Following Password Requirements:</div>] : errors;
        if (!value) {
            errors = [...errors, <div style={{ whiteSpace: 'pre' }}>&#8226; Password is Required!</div>]
            return errors;
        } else {
            return [];
        }
    }

    const onClickAnimation = () => {
        const box = document.querySelector('.errorBoxInner') as any;
        box?.animate(
            { transform: 'translateX(10px)' },
            {
                duration: 100
            }
        );
    }
    const OnSubmit = async () => {
        setSubmittedOnce(true);
        let errorsEmail = await findemailErrors();
        let errorsPass = findPasswordErrors();
        if (errorsEmail.length > 0) {
            setFormError(errorsEmail);
            setemailStatus(-1);
            onClickAnimation();
            return;
        } else if (errorsPass?.length) {
            setFormError(errorsPass);
            setPasswordStatus(-1);
            onClickAnimation();
            return;
        } else {
            setFormError([<></>]);
        }
        debouncedButton.current = debounce(() => { debouncedSubmitFunction() }, 55, debouncedButton.current());
        if (typeof debouncedButton.current === 'function') {
            debouncedButton.current();
        }
    }

    const loginFunction = async () => {
        try {
            // &#x0031;&#xFE0F;&#x20E3; Sign in user
            const userCredential = await signInWithEmailAndPassword(auth, formData.email, formData.password);
            const user = userCredential.user;

            // &#x0032;&#xFE0F;&#x20E3; Check if email is verified
            if (!user.emailVerified) {
                await signOut(auth); // Sign out if email not verified
                setFormError([<>Please verify your email before logging in!</>]);
                return;
            }

            // &#x0033;&#xFE0F;&#x20E3; Fetch user profile from Firestore
            const userDoc = await getDoc(doc(db, "users", user.uid));
            if (!userDoc.exists()) {
                setFormError([<>User data not found. Please contact support.</>]);
                return;
            }

            // &#x0034;&#xFE0F;&#x20E3; Navigate to dashboard
            navigate('/welcome', { state: { userName: userDoc.data().username }, replace: true });

        } catch (error) {
            setFormError([<>Invalid Credentials.</>]);
            console.log("Login Error:", error);
        }
    };


    const googleProvider = new GoogleAuthProvider();

    const signInWithGoogle = async () => {
        try {
            const result = await signInWithPopup(auth, googleProvider);
            const user = result.user;

            console.log("User signed in with Google:", user);

            // Check if user already exists in Firestore
            const userRef = doc(db, "users", user.uid);
            const userSnap = await getDoc(userRef);

            if (!userSnap.exists()) {
                // &#x0031;&#xFE0F;&#x20E3; New user, store additional info in Firestore
                await setDoc(userRef, {
                    username: user.displayName || "Anonymous",
                    email: user.email,
                    photoURL: user.photoURL || "",
                    createdAt: new Date().toISOString(),
                    emailVerified: user.emailVerified,
                });
            }

            // &#x0032;&#xFE0F;&#x20E3; Redirect to dashboard or home
            navigate("/welcome");

        } catch (error: any) {
            console.error("Google Sign-In Error:", error);
            let errorMessage = "An unexpected error occurred.";

            if (error.code === "auth/popup-closed-by-user") {
                errorMessage = "Google Sign-In popup closed. Please try again.";
            } else if (error.code === "auth/cancelled-popup-request") {
                errorMessage = "Google Sign-In was canceled.";
            }

            setFormError([<>{errorMessage}</>]);
        }
    };



    const debouncedSubmitFunction = async () => {
        // try {
        loginFunction();
        // } catch (e) {
        //     const errors = [<div style={{ color: 'red' }}>Following Error Occurred:</div>];
        //     setFormError([...errors, <div style={{ color: 'red' }}>{(e as any)?.response?.data?.error ? (e as any)?.response?.data?.error : "Network Error. Please Try Again!"}</div>]);
        //     console.log(e)
        // }
        (refSubmitButton?.current as any)?.blur();
    }

    const OnTabbedOnce = async () => {
        const errorsEmail = await findemailErrors();
        let errorsPass = findPasswordErrors();
        let errorBool = false;
        // console.log(tabbedCount, errorsEmail);
        if (tabbedCount > 0 && errorsEmail?.length > 0) {
            setFormError(errorsEmail);
            setemailStatus(-1);
            errorBool = true;
        } else if (tabbedCount > 1 && errorsPass?.length > 0) {
            setFormError(errorsPass);
            setPasswordStatus(-1);
            errorBool = true;
        }
        if (errorBool && tabbedCount > 2 && errorsPass?.length) {
            setPasswordStatus(-1);
        }
        if (!errorBool) {
            setFormError([<></>]);
        }
        setTabbedCount(tabbedCount + 1);
    }

    return (
        <>
            {/* <Navbar expand="lg" bg="white" variant="light" fixed="top">
                <div style={{ display: 'block', height: '55px', background: 'white' }}>
                    <Navbar.Brand style={{ position: 'absolute', right: '0px', marginRight: '5px' }}>
                        <img src={logo} width="77" alt="Blog Logo" />
                    </Navbar.Brand>
                </div>
            </Navbar > */}
            <img src={img1} alt='' style={{ height: '75px', position: 'absolute', top: '15px', right: '15px', borderRadius: '55px' }}></img>
            <div onKeyDown={(e) => { e.key === 'Enter' && OnSubmit(); e.key === 'Tab' && tabbedCount < 4 && OnTabbedOnce() }} >
                <h1>Sign In Page!</h1>
                <div className="mainGrid">
                    <label >e-mail</label>
                    <input type='email' maxLength={45} onBlur={() => OnTabbedOnce()} ref={refFirstTab as any} tabIndex={1} id={'email'} className="inputText" placeholder="Please enter your e-mail" name="email" value={formData.email} onChange={(e: any) => onChangeForm(e.target)} />
                    {emailStatus === 1 ? <CheckCircleRoundedIcon style={{ alignSelf: 'center' }} /> : emailStatus === -1 ? <ErrorRoundedIcon style={{ alignSelf: 'center' }} /> : <div style={{ visibility: 'hidden' }}></div>}
                    <label >Password</label>
                    <div style={{ position: 'relative' }}>
                        <input type='password' maxLength={45} ref={refPassTab as any} tabIndex={2} className="inputText" id={'password'} placeholder="Please enter your password" name="password" value={formData.password} onChange={(e: any) => onChangeForm(e.target)} style={{ paddingRight: '27.5px' }} />
                        <div style={{ position: 'absolute', right: '5px', top: '-5px' }}>
                            {hidePassword ? <VisibilityIcon onClick={() => setHidePassword(!hidePassword)} /> : <VisibilityOffIcon onClick={() => setHidePassword(!hidePassword)} />}
                        </div>
                    </div>
                    {passwordStatus === 1 ? <CheckCircleRoundedIcon style={{ alignSelf: 'center' }} /> : passwordStatus === -1 ? <ErrorRoundedIcon style={{ alignSelf: 'center' }} /> : <div style={{ visibility: 'hidden' }}></div>}
                    <div style={{ visibility: 'hidden' }}></div>
                    {/* <div>Show Password<input type='checkbox' onChange={() => setHidePassword(!hidePassword)} checked={!hidePassword} /></div> */}
                </div >
                <div className="mainGrid" style={{ justifyContent: 'flex-end', 'fontSize': 14 }}>
                    <div></div>
                    <NavLink to='/login/forgot-password'>Forgot Password?</NavLink>
                </div>
                {passwordStatus === -1 || emailStatus === -1 || formError.length > 0 ? <div className='errorBoxInner'>{formError}</div> : <></>}
                <div className="mainGrid" style={{ justifyContent: 'center' }}>
                    <input type='button' tabIndex={3} ref={refSubmitButton as any} className="inputButton" onClick={() => OnSubmit()} value="LOGIN" />
                    <input type='button' tabIndex={4} className="inputButton inputButtonSecondary" onClick={() => navigate('/register', { state: { email: formData.email || '' } })} value="REGISTER" />
                </div>
                <div style={{ justifyContent: 'center', 'marginTop': '15px' }}>
                    <h5>Or</h5>
                </div>
                <div style={{ justifyContent: 'center', 'marginTop': '10px' }}>
                    <button className="btn inputButton inputButtonSecondary" onClick={signInWithGoogle}>
                        <img src={logoGoogle} height='27' alt="Blog Logo" /> Sign in with Google
                    </button>
                </div>
                <div style={{ justifyContent: 'center', 'marginTop': '15px' }}>
                    <h5>Or</h5>
                </div>
                <div style={{ justifyContent: 'center', 'marginTop': '10px' }}>
                    <button className="btn inputButton" onClick={() => navigate('/ai-suggestor')}>
                        Continue as a Guest (AI-Suggestor)
                    </button>
                </div>
            </div>
        </>
    )
}

export default LoginPage;