import { Outlet } from 'react-router-dom';

export const LayoutMain = (props: any) => {
    return (
        <header>
            <div className="App-header">
                <Outlet />
            </div>
        </header>
    )
}