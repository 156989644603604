import { useEffect, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import anime from "animejs";
import img1 from '../../assets/mmk-favicon-design.png';
import MiniLoader from '../../common/loader';
import { Helmet } from "react-helmet-async";

const blogs = [
    { title: "Dealing with Depression", keywords: ["Dealing with Depression", "Get on the bandwagon", "sad", "depressed", "empathetic", "encouraging"] },
    { title: "Meditation Part 1", keywords: ["Meditation", "Pranayama", "Islamic Prayer", "Shiv-Shakti", "Awareness points to something in the Present, while focus points to the Next", "Sanzen"] },
    { title: "Dealing with Fear", keywords: ["Martian talk", "Dealing With Fear", "Defense", "Offense", "ability to fight back", "training wheels", "Train, trust, focus/let-go", "All fear can be deconditioned", "fear the greatest definition of sloth", "follow tasks vouchsafed by forefathers as objectives"] },
    { title: "Why You WILL Get Things Done!", keywords: ["plan", "the am gonna do this now", "willpower is not an infinite storehouse of energy but rather a muscle that gets excercised with practise", "Myelination of neurons", "imprints in behaviour"] },
    { title: "Dealing with Boredom", keywords: ["Dealing with Boredom/Sloth", "Lazy Lion/Lioness", "Do something Productive", "start feeling bad", "don't kill the flow", "look into the mirror",] },
    { title: "Desire is Not your Enemy", keywords: ["All desire will manifest", "Infinite Patience", "Acceptance of sorrow", "Commitment to see fulfilment", "Prayer and sacrifice", "Don't try too hard"] },
    { title: "What Makes You Work", keywords: ["We work because of Obligation Competition from Reputation, Competition for Excitement. or When doing something for someone else.", "Essentials vs projects vs motivations"] },
    { title: "Three kinds of Fame", keywords: ["Be a star in three ways", "Teenager fame vs Mediocre fame vs the real fame where you are a star among stars"] },
    { title: "The Power Of Prayer", keywords: ["Occult", "Psychosomatic healing", "survivalist biases"] },
    { title: "The End To Religious Dogmatism", keywords: ["Faith/Religion", "Prayer is not selfish", "A selfless god can never accede to selfish needs seeking to harm.", "All prayer is done so to get closer to your creator! And serve the righteous, you will!"] },
    { title: "Untying The Knot", keywords: ["Untying the knot", "Think of what you'll do in heaven if granted, on earth if your last day, or in 10 years", "uncover yourself"] },
    { title: "The Ultimate Solution To Your Problems", keywords: ["The Ultimate Solution To Your Problems Is Prayer!", "short, crisp, one-liner"] },
    { title: "Dealing with OCD", keywords: ["Dealing with OCD", "Three step program to decondition", "Sacrifice your fear to love", "shift libido"] },
    { title: "Dealing With Thought Broadcasting", keywords: ["Dealing With Thought Broadcasting", "Common Thoughts Arise", "Containing Feelings", "Not giving a fuck", "Not being afraid to face your inner desires while in your own circle.", "Know that you cannot stop someone from thinking", "Observe artists and how they channel", "so what!"] },
    { title: "Dealing With Negative Emotions", keywords: ["Focus on the small things rather than the big picture", "appreciate the creations of others", "work with interest in the field chosen for you by society", "build another skillset as a hobby, that could potentially turn into a career"] },
    { title: "Shiva vs Krishna - The Reality Model", keywords: ["Shiva vs Krishna - The Reality Model", "Hinduism", "Hold on to the I till the end or be one with others till they ascend"] },
    { title: "Skanda vs Brahma - The Discourse", keywords: ["Skanda vs Brahma - The Discourse", "Hinduism"] },
    { title: "Dealing With Emotional Hurt", keywords: ["Dealing With Emotional Hurt", "social ostracism", "seek justice", "when justice served, or whether or not, form new, reasonable goals just as how many men and women though crippled take it upon themselves to scale the tallest peaks"] },
    { title: "Dealing With Personal Demons", keywords: ["occult", "exorcism", "Unwanted psychological complexes"] },
    { title: "The Most Powerful Verses Of The Quran", keywords: ["The Most Powerful Verses Of The Quran", "Islamic Enlightenment", "Surah Rahman, Surah Yasin, Surah Al Iklas, Ayat-Ul-Kursi, Surah Al Baqarah, Surah Az-Zalzalah, Surah Mulk, Surah Waqiah, Surah Muzzamil, Panj Surahs"] },
    { title: "Does The Wrath Of God Surpass His Love", keywords: ["Does The Wrath Of God Surpass His Love", "Yahweh", "Christianity", "Abrahamic God is not a wrathful figure", "Allah is Ar-Rahman, Ar-Rahim"] },
    { title: "Your God Pretends to be Against You, But So Is The Devil", keywords: ["The Concept Of Will - Part 1", "Only You will be held accountable on the day of judgment", "cannot say you were deceived", "the test-maker will make it hard", "ultimately its about how you help self and others excel", "it's about the age of the soul, the heart, and the hidden(qaafi)"] },
    { title: "Divine Predestination - The Concept Of Will - Part 2", keywords: ["Divine Predestination - The Concept Of Will - Part 2", "Islamic", "Your wishes will be granted or deferred or granted in a different way"] },
    { title: "What Does It Take To Be A Good Muslim", keywords: ["Religion/Faith", "What Does It Take To Be A Good Muslim", "Salah and Zakat", "Tahwid and Tawakkal", "Dhikr", "God is Ar-Rahman, Ar-Rahim"] },
    { title: "The Sufi Latifas", keywords: ["Islamic Spirituality", "Sufi Latifas", "What are they and how to purify each", "From Nafs and Ruh to Kaafi and Qaafi"] },
    { title: "Honoring Our Parents: The Love, Respect, and Understanding They Deserve", keywords: ["Honoring Our Parents: The Love, Respect, and Understanding They Deserve", "Loving & Respecting Parents - The Greatest Duty", "رَبِّ ارْحَمْهُمَا كَمَا رَبَّيَانِي صَغِيرًا", "When You Don't See Eye to Eye - Understanding Their Wisdom", "The Ripple Effect - Loving Siblings, Spouses, & Friends", "When Love Means Letting Go of Ego", "The Reward of Loving & Respecting Others", "Cherish our parents while they are here, and take good care of them"] },
    { title: "Being Loved By Wifey - The Ultimate Guide", keywords: ["Being Loved By Wifey", "Recognizing How She Shows Love", "Communicate What You Need", "Appreciate & Compliment Her More", "Spend Quality Time Together", "Physical Touch & Intimacy", "Support & Protect Her Emotionally", "Positive humor adds to love", "Surprise Her With Love Gestures"] },
    { title: "Being Loved By Hubby - The Ultimate Guide", keywords: ["Being Loved By Hubby", "Understand Your Love Language", "Communicate Your Needs Without Guilt", "Appreciate His Efforts", "Build an Emotional Connection", "Physical Affection & Intimacy", "Respect & Support Him", "Surprise Him With Love Gestures"] },
    { title: "A Criticism of Intercultural Marriages", keywords: ["A Criticism of Intercultural Marriages", "The Challenges of Inter-Cultural and Interfaith Marriages", "The Significance of Faith in Marriage", "The Role of Sabr (Patience) in Choosing a Spouse", "true love is one that brings us closer to our Creator, not further from Him", "strive for marriages that uphold faith, commitment, and righteousness"] },
    { title: "Dealing With Addiction", keywords: ["Dealing With Addiction", "Replacement through initiation of either the work, or initiating to quit", "therapy, rehabilitation through proper medical care had under trained medical professionals", "the psychological and physiological aspects of addiction", "NRT, find new friends, write, research, build and create something new", "consulting a well-trained medical professional is the best way", "substance-abuse, poly-substance abuse/addictive personalities, porn addiction"] },
    { title: "The Agile Methodology", keywords: ["Agile Metholodogy", "Planning", "How Agile Works", "Agile in Different Industries", "Key Elements of Agile — Scrum, Sprints & Retrospectives", "MVP - Minimal Viable Product"] },
    { title: "Creating a Loader using UseState Hooks in React", keywords: ["novel loader purely using the useState and useEffect hooks in React",] },
    { title: "How Outdated Libraries Pose a Back-End Risk", keywords: ["Why Outdated Libraries Can Pose a Back-End Risk", "Vulnerabilities and Mitigation", "Common issues and mitigation strategies", "Node, .NET, Flutter, Selenium"] },
    { title: "The Need to Upgrade to NextJs after React-19", keywords: ["Key Features of React 19", "What Next.js Brings to the Table", "can React 19 Be Used in Next.js?", "Do You Really Need Next.js?", "Unbiased View"] },
    { title: "Using Debounce and Throttle Effectively in React", keywords: ["Debounce vs. Throttle: The Key Difference", "useRef-Based Debounce", "memoize the debounce function ", "using lodash", "throttle implementation in React"] },
    { title: "The Best Libraries to Use in your NodeJs Application", keywords: ["The Best Libraries to Use in your Node Application", "Graphical Data, Security, Performance Optimization, Storage Solutions, API and Data Fetching, Animations, Essential Libraries"] },
    { title: "The Best Libraries to Use in your .NET Application", keywords: ["The Best Libraries to Use in your .NET Application", "Graphical Data, Security, Performance Optimization, Common Storage Solutions, API and Data Fetching, Animations, Essential Libraries"] },
    { title: "Dealing With Spiritual Awakenings", keywords: ["Bite of the serpent", "Qaballah, Sufism, Chakra System, Christian Gnosticism, Yoga and meditative poses, letting desire ascend without form borrowed from parental figures is akin to letting the black flame rise on its own", "psychedelic drugs leading to imprint without a say", "circuits of consciousness", "holy books revealed to prophets"] },
];



// const openai = new OpenAI({
//     apiKey: process.env.REACT_APP_OPENAI_API_KEY,
// });

// async function getEmbedding(text) {
//     const response = await fetch("https://api.openai.com/v1/embeddings", {
//         method: "POST",
//         headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer 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`,
//         },
//         body: JSON.stringify({
//             model: "text-embedding-ada-002",
//             input: text,
//         }),
//     });
//     const data = await response.json();
//     return data.data[0].embedding; // Returns a vector
// }

// function cosineSimilarity(vecA, vecB) {
//     const dotProduct = vecA.reduce((sum, a, idx) => sum + a * vecB[idx], 0);
//     const normA = Math.sqrt(vecA.reduce((sum, a) => sum + a * a, 0));
//     const normB = Math.sqrt(vecB.reduce((sum, b) => sum + b * b, 0));
//     return dotProduct / (normA * normB);
// }

// async function findBestMatch(input) {
//     const inputEmbedding = await getEmbedding(input);
//     let bestMatch = null;
//     let bestScore = -1;

//     for (const blog of blogs) {
//         for (const keyword of blog.keywords) {
//             const keywordEmbedding = await getEmbedding(keyword);
//             const similarity = cosineSimilarity(inputEmbedding, keywordEmbedding);
//             if (similarity > bestScore) {
//                 bestScore = similarity;
//                 bestMatch = blog;
//             }
//         }
//     }
//     return bestMatch;
// }

const MoodSuggester = () => {
    const [mood, setMood] = useState("");
    const [input, setInput] = useState("");
    const [suggestedBlog, setSuggestedBlogForMood] = useState(null);
    const [suggestedBlogLoader, setSuggestedBlogForMoodLoader] = useState(null);
    const [suggestedBlogForInput, setSuggestedBlogForInput] = useState(false);
    const [suggestedBlogForInputLoader, setSuggestedBlogForInputLoader] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    const emojis = ["😀", "😢", "😡", "😱", "😎", "🤔"];

    useEffect(() => {
        window.scroll(0, 0);
        anime({
            targets: "#typewriter-text",
            opacity: [0.25, 1],
            duration: 7575,
            easing: "easeOutExpo",
            loop: true
        });
    }, []);

    const getSuggestedBlogForMoodLink = (titleSuggestionfromChatGPT, blogs) => {
        // Normalize the title (convert to lowercase for better matching)
        const normalizedTitle = titleSuggestionfromChatGPT.toLowerCase();
        // Find the best-matching blog based on common words in title
        let matchedBlog = blogs.find(blog =>
            blog.title.toLowerCase().includes(normalizedTitle) ||
            blog.keywords.some(keyword => keyword.toLowerCase().includes(normalizedTitle))
        );

        // If no exact match, try fuzzy search (check word similarity)
        if (!matchedBlog) {
            matchedBlog = blogs.find(blog => {
                const blogWords = blog.title.toLowerCase().split(" ");
                const titleWords = normalizedTitle.split(" ");
                return titleWords.some(word => blogWords.includes(word));
            });
        }

        // If a match is found, create a slug (URL-friendly format)
        if (matchedBlog) {
            const slug = matchedBlog.title.toLowerCase()
                .replace(/[^a-zA-Z0-9 ]/g, "") // Remove special characters
                .replace(/\s+/g, "-"); // Replace spaces with dashes
            if (slug === 'dealing-with-depression') {
                return `/blog/post/dealing-with-depression`;
            } else if (slug === 'dealing-with-fear') {
                return `/blog/post/dealing-with-fear`;
            } else if (slug === 'meditation-part-1') {
                return `/blog/post/meditation-Part-1`;
            } else if (slug === 'why-you-will-get-things-done') {
                return `/blog/post/get-things-done`;
            } else if (slug === 'dealing-with-boredom') {
                return `/blog/post/dealing-with-boredom`;
            } else if (slug === 'desire-is-not-your-enemy') {
                return `/blog/post/desire-is-not-your-enemy`;
            } else if (slug === 'what-makes-you-work') {
                return `/blog/post/what-makes-you-work`;
            } else if (slug === 'three-kinds-of-fame') {
                return `/blog/post/three-kinds-of-fame`;
            } else if (slug === 'shiva-vs-krishna-the-reality-model') {
                return `/blog/post/shiva-vs-krishna`;
            } else if (slug === 'the-power-of-prayer') {
                return `/blog/post/power-of-prayer`;
            } else if (slug === 'the-end-to-religious-dogmatism') {
                return `/blog/post/end-to-religious-dogmatism`;
            } else if (slug === 'untying-the-knot') {
                return `/blog/post/untying-the-knot`;
            } else if (slug === 'dealing-with-ocd') {
                return `/blog/post/dealing-with-OCD`;
            } else if (slug === 'dealing-with-thought-broadcasting') {
                return `/blog/post/dealing-with-thought-broadcasting`;
            } else if (slug === 'the-ultimate-solution') {
                return `/blog/post/the-ultimate-solution-to-your-problems`;
            } else if (slug === 'dealing-with-negative-emotions') {
                return `/blog/post/dealing-with-negative-emotions`;
            } else if (slug === 'dealing-with-emotional-hurt') {
                return `/blog/post/dealing-with-emotional-hurt`;
            } else if (slug === 'dealing-with-personal-demons') {
                return `/blog/post/personal-demons`;
            } else if (slug === 'dealing-with-spiritual-awakenings') {
                return `/blog/post/dealing-with-spiritual-awakenings`;
            } else if (slug === 'skanda-vs-brahma-the-discourse') {
                return `/blog/post/skanda-vs-brahma`;
            } else if (slug === 'the-most-powerful-verses-of-the-quran') {
                return `/blog/post/the-most-powerful-verses`;
            } else if (slug === 'does-the-wrath-of-god-surpass-his-love') {
                return `/blog/post/wrath-vs-love-of-god`;
            } else if (slug === 'your-god-pretends-to-be-against-you-but-so-is-the-devil') {
                return `/blog/post/the-concept-of-will-part-1`;
            } else if (slug === 'divine-predestination-the-concept-of-will-part-2') {
                return `/blog/post/the-concept-of-will-part-2`;
            } else if (slug === 'being-a-good-muslim') {
                return `/blog/post/being-a-good-muslim`;
            } else if (slug === 'the-sufi-latifas') {
                return `/blog/post/the-latifas`;
            } else if (slug === 'honoring-our-parents-the-love-respect-and-understanding-they-deserve') {
                return `/blog/post/honouring-our-parents`;
            } else if (slug === 'being-loved-by-hubby-the-ultimate-guide') {
                return `/blog/post/loving-your-hubby`;
            } else if (slug === 'being-loved-by-wifey-the-ultimate-guide') {
                return `/blog/post/loving-your-wifey`;
            } else if (slug === 'dealing-with-addiction') {
                return `/blog/post/dealing-with-addiction`;
            } else if (slug === 'the-agile-methodology') {
                return `/blog/post/agile`;
            } else if (slug === 'the-best-libraries-to-use-in-your-nodejs-application') {
                return `/blog/post/best-libraries-node`;
            } else if (slug === 'the-best-libraries-to-use-in-your-net-application') {
                return `/blog/post/best-libraries-dot-net-core`;
            } else if (slug === 'creating-a-loader-using-usestate-hooks-in-react') {
                return `/blog/post/react-usestate-loader`;
            } else if (slug === 'how-outdated-libaries-pose-a-back-end-risk') {
                return `/blog/post/outdated-libaries-back-end-risk`;
            } else if (slug === 'the-need-to-upgrade-to-nextjs-after-react-19') {
                return `/blog/post/upgrade-to-next-after-react19`;
            } else if (slug === 'using-debounce-and-throttle-effectively-in-react') {
                return `/blog/post/using-debounce-and-throttle-effectively-in-react`;
            } else if (slug === 'a-criticism-of-intercultural-marriages') {
                return `/blog/post/criticism-of-intercultural-marriages`;
            }
            return `/blog/post/honouring-our-parents`;
        }
        return `/blog/post/honouring-our-parents`;
    };

    const getRandomPost = () => {
        const routes = [`/blog/post/agile`, '/blog/post/being-a-good-muslim', 'honouring-our-parents', '/blog/post/the-concept-of-will-part-1', '/blog/post/the-concept-of-will-part-2', '/blog/post/desire-is-not-your-enemy', '/blog/post/get-things-done', '/blog/post/the-latifas', '/blog/post/meditation-Part-1', '/blog/post/dealing-with-fear', '/blog/post/untying-the-knot', '/blog/post/the-most-powerful-verses-of-the-quran'];
        return routes[Math.floor(Math.random() * 12)];
    }


    const handleMood = async (e) => {
        setMood(e);
        setSuggestedBlogForMoodLoader(true);
        getRecommendations(e, blogs).then((e) => {
            const cleanJSON = e.replace(/^"|"$/g, "").replace(/\\n/g, "").trim(); // Remove extra quotes & newlines
            try {
                let parsedData = JSON.parse(cleanJSON);

                // Ensure 'explanation' exists; if not, add a default message
                if (!parsedData.explanation) {
                    parsedData.explanation = "No detailed explanation available, but this article is recommended based on your mood.";
                }

                setSuggestedBlogForMood(parsedData);
            } catch (error) {
                console.error("JSON Parsing Error:", error, "Response:", e);
            }
        }).catch((e) => { console.error(e); setSuggestedBlogForMood({ title: 'AI Error', explanation: 'You are either disconnected from the internet, or your bandwidth potential has been reached.' }) }).finally(() => {
            setSuggestedBlogForMoodLoader(false);
        });
    };

    const handleTextKeyDown = async (event) => {
        if (event.key === 'Enter') {
            setSuggestedBlogForInputLoader(true);
            getRecommendationsKeywords(input, blogs).then((e) => {
                // console.log("Raw OpenAI Response:", e);
                const cleanJSON = e.replace(/^"|"$/g, "").replace(/\\n/g, "").trim(); // Remove extra quotes & newlines
                try {
                    let parsedData = JSON.parse(cleanJSON);

                    // Ensure 'explanation' exists; if not, add a default message
                    if (!parsedData.explanation) {
                        parsedData.explanation = "No detailed explanation available, but this article is recommended based on your mood.";
                    }

                    setSuggestedBlogForInput(parsedData);
                } catch (error) {
                    console.error("JSON Parsing Error:", error, "Response:", e);
                }
            }).catch((e) => { console.error(e); setSuggestedBlogForInput({ title: 'AI Error', explanation: 'You are either disconnected from the internet, or your bandwidth potential has been reached.' }) }).finally(() => {
                setSuggestedBlogForInputLoader(false);
            });
        }
    }

    async function getRecommendations(mood, articles) {
        const response = await fetch("https://matkhan-express-7badb55a96b5.herokuapp.com/api/chatMood", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ mood }),
        });

        // const response = await openai.chat.completions.create({
        //     model: "gpt-3.5-turbo",
        //     messages: [
        //         { role: "system", content: "You are an AI that recommends articles based on user mood. Always return a JSON object with both 'title' and 'explanation' fields." },
        //         { role: "user", content: `I'm feeling ${mood}. Suggest only the title of only one relevant article from this exact list, and do not generate new titles. Only pick from the provided list:\n${articleTitles}. Return a JSON object like {title: '...', explanation: '...'} and nothing else.` },
        //     ],
        //     max_tokens: 150,
        // });
        const data = await response.json();

        return data.choices[0].message.content;
    }


    async function getRecommendationsKeywords(input, articles) {
        const response = await fetch("https://matkhan-express-7badb55a96b5.herokuapp.com/api/chatInput", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ input }),
        });
        const data = await response.json();

        return data.choices[0].message.content;
    }

    return (
        <div className="ai container d-flex flex-column align-items-center justify-content-center min-vh-100 p-4 bg-light">
            <img alt='' className='inverse' src={img1} onClick={() => navigate('/')} style={{ height: '75px', position: 'absolute', top: '15px', right: '15px', borderRadius: '55px' }}></img>
            <div className='inverse'>
                <Helmet>
                    <title>AI Suggestor on matkhan.net</title>
                    <meta property="og:title" content='AI Suggestor on matkhan.net' />
                    <meta property="og:description" content={`Read various articles from matkhan.net using the AI suggestor`} />
                    <meta property="og:image" content='https://matkhan-express-7badb55a96b5.herokuapp.com/mmk-favicon-4.png' />
                    <meta property="og:url" content={`https://www.matkhan.net${location.pathname}`} />
                    <meta property="og:type" content="article" />
                </Helmet>

                {/* Typewriter Text */}
                <div className="text-center bg-primary text-white p-3 rounded mb-4" style={{ marginTop: '85px' }}>
                    <h1 id="typewriter-text">
                        Let <span className="text-warning">AI</span> do the magic for you!
                    </h1>
                </div>

                {/* Mood Input Section */}
                <div className="card shadow-lg p-4 w-100" style={{ maxWidth: "500px" }}>
                    <h2 className="text-center mb-3">How are you feeling today?</h2>
                    <input
                        type="text"
                        placeholder="Go on! Type something... Then hit 'Enter'"
                        value={input}
                        maxLength={45}
                        onChange={(e) => setInput(e.target.value)}
                        className="form-control text-center mb-3"
                        onKeyDown={handleTextKeyDown}
                        style={{ fontSize: "15px", height: "45px" }}
                    />
                    {(suggestedBlogForInputLoader || suggestedBlogForInput) && (
                        <div className="alert alert-light border" onClick={() => navigate(getSuggestedBlogForMoodLink(suggestedBlogForInput.title, blogs), { state: { from: 'ai-suggestor' } })}>
                            {suggestedBlogForInputLoader ? <MiniLoader /> : <><h3 className="text-success">{suggestedBlogForInput.title}</h3>
                                <p className="text-muted" style={{ fontSize: "15px" }}>{suggestedBlogForInput.explanation}</p></>}
                        </div>
                    )}
                </div>

                {/* Emoji Mood Selector */}
                <div className="card shadow-lg p-4 w-100 mt-4" style={{ maxWidth: "500px" }}>
                    <h2 className="text-center mb-3">Or pick an emoji!</h2>
                    <div className="d-flex justify-content-center gap-4" style={{ flexWrap: 'wrap' }}>
                        {emojis.map((emoji, index) => (
                            <button
                                key={index}
                                className={`inverse btn btn- lg ${mood === emoji ? "btn-primary text-white" : "btn-outline-secondary"}`}
                                onClick={() => handleMood(emoji)}
                                style={{ fontSize: "25px", height: "45px" }}
                            >
                                {emoji}
                            </button>
                        ))}
                    </div>
                    {(suggestedBlog || suggestedBlogLoader) && (
                        <div className="alert alert-light border mt-3" onClick={() => navigate(getSuggestedBlogForMoodLink(suggestedBlog.title, blogs), { state: { from: 'ai-suggestor' } })}>
                            {suggestedBlogLoader ? <MiniLoader /> : (<>
                                <h3 className="text-success">{suggestedBlog.title}</h3>
                                <p className="text-muted" style={{ fontSize: "15px" }}>{suggestedBlog.explanation}</p>
                            </>)}
                        </div>
                    )}
                </div>

                {/* Surprise Section */}
                <div className="card shadow-lg p-4 w-100 mt-4 text-center" style={{ maxWidth: "500px" }}>
                    <h2 className="mb-3">Surprise Me!</h2>
                    <p className="text-muted" style={{ fontSize: "20px" }}>How about a random AI-selected fun post?</p>
                    <button className="btn btn-outline-primary btn-lg" onClick={() => { navigate(getRandomPost(), { state: { from: 'ai-suggestor' } }) }} style={{ fontSize: "20px", padding: "10px 20px" }}>
                        Go To Post!
                    </button>
                </div>
            </div>
        </div>
    );
};

export default MoodSuggester;
