import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'

const VerifyEmail = () => {
    const navigate = useNavigate();
    return (
        <div style={{ display: 'grid', gridTemplateColumns: '255px, 255px', alignContent: 'center', padding: '25px' }}>
            <h4>
                Kindly verify your email, and click here to go back to the login screen when verified!
            </h4>
            <Button onClick={() => navigate('/login')} style={{ fontSize: '12px' }}> Go to Login! </Button>
        </div>
    )
}

export default VerifyEmail;