import { useState, useEffect } from "react";
import { getAuth } from "firebase/auth";
import { getFirestore, collection, addDoc } from "firebase/firestore";

const auth = getAuth();
const db = getFirestore();

const useTrackRouteVisits = () => {
    const [currentPath, setCurrentPath] = useState(window.location.pathname);

    useEffect(() => {
        // ✅ Function to log route visits
        const logRouteVisit = async () => {
            try {
                const user = auth.currentUser; // Get logged-in user (if any)

                await addDoc(collection(db, "route_visits"), {
                    userId: user ? user.uid : "anonymous", // ✅ Store UID or "anonymous"
                    email: user ? user.email : null, // ✅ Store email if logged in
                    route: window.location.pathname, // ✅ Track route
                    timestamp: new Date().toISOString(),
                });

                console.log(`✅ Logged route visit: ${window.location.pathname}`);
            } catch (error) {
                console.error("❌ Error logging route visit:", error);
            }
        };

        // ✅ Log first visit
        logRouteVisit();

        // ✅ Listen for manual URL changes (for SPAs)
        const handleRouteChange = () => {
            setCurrentPath(window.location.pathname);
            logRouteVisit(); // Log new route
        };

        window.addEventListener("popstate", handleRouteChange); // ✅ Detect back/forward navigation
        window.addEventListener("pushstate", handleRouteChange); // ✅ Detect programmatic navigation

        return () => {
            window.removeEventListener("popstate", handleRouteChange);
            window.removeEventListener("pushstate", handleRouteChange);
        };
    }, []);

    return currentPath; // Return the current route if needed
};

export default useTrackRouteVisits;
