import { useState } from 'react';
import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
import { Outlet, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../../../assets/MMK10.svg";
import './layout.css';
import { signOut } from "firebase/auth";
import { useFirebase } from '../../auth/context';
import { Button } from 'react-bootstrap';
import SubscriptionModal from './SubscriptionModal';

function LayoutBlog() {
    const { auth } = useFirebase();
    const navigate = useNavigate();

    const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);

    return (
        <>
            {/* &#x2705; React Bootstrap Navbar */}
            <Navbar expand="lg" bg="light" variant="light" fixed="top">
                <Container>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Navbar.Brand href="/blog/home" className="dissappear" style={{ paddingLeft: '10px' }}>
                            <Nav.Link href="/blog/home">Home</Nav.Link>
                        </Navbar.Brand>
                        <Nav className="ml-auto">
                            <NavDropdown title="General Articles" id="nav-dropdown-general">
                                <NavDropdown.Item href="/blog/post/get-things-done">
                                    Why you don't get anything done!?
                                </NavDropdown.Item>
                                <NavDropdown.Item href="/blog/post/desire-is-not-your-enemy">
                                    Desire is not your enemy
                                </NavDropdown.Item>
                                <NavDropdown.Item href="/blog/post/dealing-with-boredom">
                                    Dealing with boredom
                                </NavDropdown.Item>
                                <NavDropdown.Item href="/blog/post/three-kinds-of-fame">
                                    Three kinds of fame
                                </NavDropdown.Item>
                                <NavDropdown.Item href="/blog/post/what-makes-you-work">
                                    What makes you work
                                </NavDropdown.Item>
                                <NavDropdown.Item href="/blog/post/get-things-done">
                                    Get it done!
                                </NavDropdown.Item>
                            </NavDropdown>

                            <NavDropdown title="Technology/Code" id="nav-dropdown-tech">
                                <NavDropdown.Item href="/blog/post/react-usestate-loader">
                                    Creating a Loader With useState Hooks in React
                                </NavDropdown.Item>
                                <NavDropdown.Item href="/blog/post/agile">
                                    Advantages of Agile Methodology
                                </NavDropdown.Item>
                                <NavDropdown.Item href="/blog/post/best-libraries-node">
                                    Best Libraries to Use in Your Node Application
                                </NavDropdown.Item>
                                <NavDropdown.Item href="/blog/post/best-libraries-dot-net-core">
                                    Best Libraries to Use in Your .NET Application
                                </NavDropdown.Item>
                                <NavDropdown.Item href="/blog/post/outdated-libaries-back-end-risk">
                                    Why Outdated Libraries Can Pose a Back-End Risk
                                </NavDropdown.Item>
                                <NavDropdown.Item href="/blog/post/using-debounce-and-throttle-effectively-in-react">
                                    How to Use Debounce/Throttle Properly in React
                                </NavDropdown.Item>
                                <NavDropdown.Item href="/blog/post/upgrade-to-next-after-react19">
                                    Do we really have to Upgrade to NextJS after React 19?
                                </NavDropdown.Item>
                            </NavDropdown>

                            <NavDropdown title="Faith/Religion" id="nav-dropdown-faith">
                                <NavDropdown.Item href="/blog/post/wrath-vs-love-of-god">
                                    Does the Wrath Of God Surpass His Love?
                                </NavDropdown.Item>
                                <NavDropdown.Item href="/blog/post/the-most-powerful-verses">
                                    The Most Powerful Verses of The Quran
                                </NavDropdown.Item>
                                <NavDropdown.Item href="/blog/post/the-concept-of-will-part-1">
                                    Your God Pretends To Be Against You, But So Is The Devil.
                                </NavDropdown.Item>
                                <NavDropdown.Item href="/blog/post/the-concept-of-will-part-2">
                                    The Concept of Will - Part 2.
                                </NavDropdown.Item>
                                <NavDropdown.Item href="/blog/post/being-a-good-muslim">
                                    What does it take to be a Good Muslim?
                                </NavDropdown.Item>
                                <NavDropdown.Item href="/blog/post/end-to-religious-dogmatism">
                                    An End To Religious Dogmatism
                                </NavDropdown.Item>
                                <NavDropdown.Item href="/blog/post/criticism-of-intercultural-marriages">
                                    A Criticism of Inter-Cultural Marriages and an Explanation of the Afterlife
                                </NavDropdown.Item>
                                {/* <NavDropdown.Item href="/blog/post/wrath-vs-love-of-god">
                                    Does the Wrath Of God Surpass His Love?
                                </NavDropdown.Item>
                                <NavDropdown.Item href="/blog/post/the-most-powerful-verses">
                                    The Most Powerful Verses of The Quran
                                </NavDropdown.Item> */}
                                {/* <li><a class="dropdown-item" href="/blog/post/Islam">The Inner Expansion of Islam</a></li> */}
                                {/* <li><a class="dropdown-item" href="/blog/post/Islam">Balancing the Spiritual and the Scientific</a></li> */}
                            </NavDropdown>

                            <NavDropdown title="Occult/Spirituality" id="nav-dropdown-faith">
                                <NavDropdown.Item href="/blog/post/power-of-prayer">
                                    The Power Of Prayer
                                </NavDropdown.Item>
                                <NavDropdown.Item href="/blog/post/dealing-with-spiritual-awakenings">
                                    Dealing with Spiritual Awakenings
                                </NavDropdown.Item>
                                <NavDropdown.Item href="/blog/post/personal-demons">
                                    Personal Demons
                                </NavDropdown.Item>
                                <NavDropdown.Item href="/blog/post/meditation-Part-1">
                                    Meditation - Part 1
                                </NavDropdown.Item>
                                <NavDropdown.Item href="/blog/post/the-latifas">
                                    The Sufi Latifas
                                </NavDropdown.Item>
                                <NavDropdown.Item href="/blog/post/untying-the-knot">
                                    Untying the knot (side article)
                                </NavDropdown.Item>
                            </NavDropdown>


                            <NavDropdown title="Mental Health" id="nav-dropdown-faith">
                                <NavDropdown.Item href="/blog/post/dealing-with-fear">
                                    Dealing with Fear
                                </NavDropdown.Item>
                                <NavDropdown.Item href="/blog/post/dealing-with-depression">
                                    Dealing with Depression
                                </NavDropdown.Item>
                                <NavDropdown.Item href="/blog/post/dealing-with-OCD">
                                    Dealing with OCD
                                </NavDropdown.Item>
                                <NavDropdown.Item href="/blog/post/dealing-with-thought-broadcasting">
                                    Dealing with Thought Broadcasting
                                </NavDropdown.Item>
                                <NavDropdown.Item href="/blog/post/dealing-with-negative-emotions">
                                    Dealing with Negative Emotions
                                </NavDropdown.Item>
                                <NavDropdown.Item href="/blog/post/dealing-with-addiction">
                                    Dealing with Addiction
                                </NavDropdown.Item>
                                <NavDropdown.Item href="/blog/post/dealing-with-emotional-hurt">
                                    Dealing with Emotional Hurt
                                </NavDropdown.Item>
                                <NavDropdown.Item href="/blog/post/the-ultimate-solution">
                                    The ultimate solution to all your problems
                                </NavDropdown.Item>
                            </NavDropdown>

                            <NavDropdown title="Family" id="nav-dropdown-faith">
                                <NavDropdown.Item href="/blog/post/honouring-our-parents">
                                    Honoring Our Parents: Love And Respect!
                                </NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>

                    <Navbar.Brand href="/blog/home">
                        <img src={logo} width="77" alt="Blog Logo" />
                    </Navbar.Brand>
                </Container>

            </Navbar >
            <hr style={{ marginTop: "0px;" }} />
            <div className="container body-content" style={{
                marginLeft: '5%', width: "89%",
                backgroundImage: "linear-gradient(to top, #48c6ef 0%, rgb(0, 0, 0) 100%)"
            }}>
                <Outlet />
            </div>
            <footer className="footer">
                <p style={{ fontSize: '12px', marginTop: '20px' }}>
                    <button style={{ color: 'slateblue', textDecoration: 'underline' }} onClick={() => { auth?.currentUser ? setShowSubscriptionModal(true) : navigate('/') }}> Subscribe To The NewsLetter! </button>
                </p><p>   <ul id="horizontal-list" style={{ listStyle: 'none', display: 'inline-flex' }}>
                    <li style={{ fontSize: '12px', color: 'slateblue', textDecoration: 'underline' }}>
                        <a href="/privacy-policy" className="my-className"> Privacy Policy </a>
                    </li><li>
                        |
                    </li>
                    <li style={{ fontSize: '12px', color: 'slateblue', textDecoration: 'underline' }}>
                        <a href="https://www.linkedin.com/in/matheen-mohammed-khan/" className="my-className"> Visit my LinkedIn</a>
                    </li>
                </ul>
                </p>
                {showSubscriptionModal && <SubscriptionModal show={showSubscriptionModal} handleClose={() => setShowSubscriptionModal(!showSubscriptionModal)} />}
                <p>
                    {auth?.currentUser && <Button onClick={async () => {
                        await signOut(auth);
                    }}>Sign Out!</Button>}
                </p>
                <p>{new Date().getFullYear()} MMK All Rights Reserved</p>
            </footer>
        </>
    );
}

export { LayoutBlog };