// function LoginDebounce<Params extends any[]>(callback: (...args: Params) => any, timer: number): (...args: Params) => void {
//     let timeout: NodeJS.Timeout;
//     return function (...args: Params) {
//         clearTimeout(timeout);
//         timeout = setTimeout(() => callback(...args), timer);
//         return timeout;
//     }
// }

function debounce<Params extends any[]>(
    func: (...args: Params) => any,
    timeout: number, ExistingTimer: any = null
): (...args: Params) => void {
    var timer: NodeJS.Timeout
    if (ExistingTimer) {
        timer = ExistingTimer;
    }
    return (...args: Params) => {
        clearTimeout(timer)
        timer = setTimeout(() => {
            func(...args)
        }, timeout)
        return timer
    }
}



export default debounce;