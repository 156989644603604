import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import InitialPage from './features/login/initial-page';
import LoginPage from './features/login/login-page';
import VerifyEmail from './features/login/verify-email';
import RegistrationPage from './features/login/register';
import WelcomePage from './features/login/welcome-page';
import { Home } from './features/blog/Blog/Home'
import { Post } from './features/blog/Blog/Post'
import { LayoutMain } from './features/login/layout-main';
import { LayoutBlog } from './features/blog/Blog/LayoutBlog';
import MoodSuggester from './features/ai/ai-presentational';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import ProtectedRoute from './features/auth/protected-route';
import ForgotPassword from './features/login/forgot-password';
import { RouteContextProvider } from './features/auth/route-context';
import NotFound from './features/login/not-found';
import PrivacyPolicy from './features/login/privacy-policy';
import { useFirebase } from './features/auth/context';
import { browserLocalPersistence, onAuthStateChanged, setPersistence } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { auth } from './features/auth/firebase';
import useTrackRouteVisits from "./useTrackRouteVisits";
import NavigationRoute from './features/auth/navigation-route';

setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log("User session will persist across browser refreshes.");
  })
  .catch((error) => {
    console.error("Persistence error:", error);
  });

const App = () => {
  const { auth, db } = useFirebase();
  useTrackRouteVisits();

  useEffect(() => {
    // Track Logged-in Users
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userRef = doc(db, "users", user.uid);
        await setDoc(userRef, {
          uid: user.uid,
          email: user.email,
          lastLogin: new Date().toISOString(),
        }, { merge: true });
      }
    });

    return () => unsubscribe();
  }, []);


  return (
    <div className="App">
      <header>
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<RouteContextProvider><LayoutMain /></RouteContextProvider>}>
              <Route path='/' element={<NavigationRoute><InitialPage /></NavigationRoute>}></Route>
              <Route path='*' element={<NotFound />}></Route>
              <Route path='/login' element={<NavigationRoute><LoginPage /></NavigationRoute>}></Route>
              <Route path='/login/forgot-password' element={<NavigationRoute><ForgotPassword /></NavigationRoute>}></Route>
              <Route path='/verify-email' element={<NavigationRoute><VerifyEmail /></NavigationRoute>}></Route>
              <Route path='/privacy-policy' element={<PrivacyPolicy />}></Route>
              <Route path='/register' element={<NavigationRoute><RegistrationPage /></NavigationRoute>}></Route>
              <Route path='/ai-suggestor' element={<MoodSuggester />}></Route>
              <Route path='/welcome' element={<WelcomePage />}></Route>
              <Route path='/blog' element={<ProtectedRoute><LayoutBlog /></ProtectedRoute>}>
                <Route path='*' element={<Post slug="/blog/post/being-a-good-muslim" />}></Route>
                <Route path='/blog/home' element={<Home />}></Route>
                {/* <Route path='/blog/post' element={<Post />}></Route> */}
                <Route path='/blog/post/dealing-with-depression' element={<Post slug="dealing-with-depression" />}></Route>
                <Route path='/blog/post/dealing-with-fear' element={<Post slug="dealing-with-fear" />}></Route>
                <Route path='/blog/post/meditation-Part-1' element={<Post slug="meditation-Part-1" />}></Route>
                <Route path='/blog/post/power-of-prayer' element={<Post slug="power-of-prayer" />}></Route>
                <Route path='/blog/post/shiva-vs-krishna' element={<Post slug="shiva-vs-krishna" />}></Route>
                <Route path='/blog/post/get-things-done' element={<Post slug="get-things-done" />}></Route>
                <Route path='/blog/post/dealing-with-boredom' element={<Post slug="dealing-with-boredom" />}></Route>
                <Route path='/blog/post/end-to-religious-dogmatism' element={<Post slug="the-end-to-religious-dogmatism" />}></Route>
                <Route path='/blog/post/untying-the-knot' element={<Post slug="untying-the-knot" />}></Route>
                <Route path='/blog/post/desire-is-not-your-enemy' element={<Post slug="desire-is-not-your-enemy" />}></Route>
                <Route path='/blog/post/what-makes-you-work' element={<Post slug="what-makes-you-work" />}></Route>
                <Route path='/blog/post/three-kinds-of-fame' element={<Post slug="three-kinds-of-fame" />}></Route>
                <Route path='/blog/post/dealing-with-OCD' element={<Post slug="dealing-with-OCD" />}></Route>
                <Route path='/blog/post/the-ultimate-solution' element={<Post slug="the-ultimate-solution" />}></Route>
                <Route path='/blog/post/dealing-with-spiritual-awakenings' element={<Post slug="dealing-with-spiritual-awakenings" />}></Route>
                <Route path='/blog/post/general-hustle.gif' element={<img alt='' src='./assets/general-hustle.gif' />}></Route>
                <Route path='/blog/post/dealing-with-thought-broadcasting' element={<Post slug="dealing-with-thought-broadcasting" />}></Route>
                <Route path='/blog/post/skanda-vs-brahma' element={<Post slug="skanda-vs-brahma" />}></Route>
                <Route path='/blog/post/dealing-with-negative-emotions' element={<Post slug="dealing-with-negative-emotions" />}></Route>
                <Route path='/blog/post/dealing-with-emotional-hurt' element={<Post slug="dealing-with-emotional-hurt" />}></Route>
                <Route path='/blog/post/personal-demons' element={<Post slug="personal-demons" />}></Route>
                <Route path='/blog/post/the-most-powerful-verses' element={<Post slug="/blog/post/the-most-powerful-verses" />}></Route>
                <Route path='/blog/post/wrath-vs-love-of-god' element={<Post slug="/blog/post/wrath-vs-love-of-god" />}></Route>
                <Route path='/blog/post/the-concept-of-will-part-1' element={<Post slug="/blog/post/the-concept-of-will-part-1" />}></Route>
                <Route path='/blog/post/the-concept-of-will-part-2' element={<Post slug="/blog/post/the-concept-of-will-part-2" />}></Route>
                <Route path='/blog/post/being-a-good-muslim' element={<Post slug="/blog/post/being-a-good-muslim" />}></Route>
                <Route path='/blog/post/the-latifas' element={<Post slug="/blog/post/the-latifas" />}></Route>
                <Route path='/blog/post/honouring-our-parents' element={<Post slug="/blog/post/honouring-our-parents" />}></Route>
                <Route path='/blog/post/loving-your-hubby' element={<Post slug="/blog/post/loving-your-hubby" />}></Route>
                <Route path='/blog/post/loving-your-wifey' element={<Post slug="/blog/post/loving-your-wifey" />}></Route>
                <Route path='/blog/post/criticism-of-intercultural-marriages' element={<Post slug="/blog/post/criticism-of-intercultural-marriages" />}></Route>
                <Route path='/blog/post/dealing-with-addiction' element={<Post slug="/blog/post/dealing-with-addiction" />}></Route>
                <Route path='/blog/post/upgrade-to-next-after-react19' element={<Post slug="/blog/post/upgrade-to-next-after-react19" />}></Route>
                <Route path='/blog/post/best-libraries-dot-net-core' element={<Post slug="/blog/post/best-libraries-dot-net-core" />}></Route>
                <Route path='/blog/post/best-libraries-node' element={<Post slug="/blog/post/best-libraries-node" />}></Route>
                <Route path='/blog/post/outdated-libaries-back-end-risk' element={<Post slug="/blog/post/outdated-libaries-back-end-risk" />}></Route>
                <Route path='/blog/post/agile' element={<Post slug="/blog/post/agile" />}></Route>
                <Route path='/blog/post/ramadan-is-difficult' element={<Post slug="/blog/post/ramadan-is-difficult" />}></Route>
                <Route path='/blog/post/using-debounce-and-throttle-effectively-in-react' element={<Post slug="/blog/post/using-debounce-and-throttle-effectively-in-react" />}></Route>
                <Route path='/blog/post/react-usestate-loader' element={<Post slug="/blog/post/react-usestate-loader" />}></Route>
              </Route>
            </Route>
          </Routes>
        </BrowserRouter>
      </header>
    </div >
  );
}

export default App;
