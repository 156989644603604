import { createContext, useContext, useState } from "react";

const RouteContext = createContext();

export const RouteContextProvider = ({ children }) => {
    const [path, setPath] = useState('/blog/home');

    return (<RouteContext.Provider value={{ path, setPath }}>
        {children}
    </RouteContext.Provider>
    )
}

export const useRouteContext = () => useContext(RouteContext);