import { useEffect } from 'react';
// import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import anime from 'animejs/lib/anime.es.js';
import { useRouteContext } from '../auth/route-context';

const WelcomePage = () => {
  const navigate = useNavigate();
  const existingRoute = useRouteContext();

  useEffect(() => {
    window.scroll(0, 0);
    const element = document.querySelector('.text-animation') as HTMLElement;
    const lettersHtml = element?.textContent?.replace(/\S/g, '<span class="letter">$&</span>');
    element.innerHTML = `<div class="letters">${lettersHtml}</div><span class="cursor"></span>`;
    element.style.display = 'block';

    const letters = Array.from(element.querySelectorAll('.letter')) as Array<HTMLElement>;
    const TYPE_AFTER_MS = 555;
    const JUMP_AFTER_MS = 155;

    const blink = anime({
      targets: '.text-animation .cursor',
      loop: true,
      duration: 255,
      opacity: [
        { value: [1, 1] },
        { value: [0, 0] }
      ],
    });

    const animation = anime.timeline({ loop: false })
      .add({
        targets: '.text-animation .cursor',
        translateX: letters.map((letter, i) =>
          ({ value: letter.offsetLeft + letter.offsetWidth, duration: 1, delay: i === 0 ? 0 : JUMP_AFTER_MS }))
      }, TYPE_AFTER_MS)
      .add({
        targets: '.text-animation .letter',
        opacity: [0, 1],
        duration: 1,
        delay: anime.stagger(JUMP_AFTER_MS),
        changeBegin: () => {
          // blink.reset();
          blink.pause();
        },
        changeComplete: () => {
          blink.restart();
        }
      }, TYPE_AFTER_MS)
      .add({
        targets: '.text-animation',
        opacity: 0,
        delay: anime.stagger(2 * JUMP_AFTER_MS),
        duration: 500,
        easing: 'easeOutExpo',
      });
    animation.finished.then(() => {
      if (existingRoute && existingRoute.path?.includes('post')) { navigate(existingRoute.path, { replace: true }); }
      else navigate('/blog/home', { replace: true })
    });
  }, [])

  return (
    <>
      <div className='cursorContainer'>
        <h4><div className="text-animation">
          Welcome To My Blog!
        </div></h4>
      </div>
    </>
  )
}

export default WelcomePage;