import './Home.css'
import React, { useEffect, useState } from 'react';
import photo from '../../../assets/accion-pic.jpeg';
import anime from "animejs";
import { useNavigate, useLocation } from 'react-router-dom'
import img1 from '../../../assets/mmk-favicon-4.png';
import { Helmet } from "react-helmet-async";


const FancyButton = () => {
    const navigate = useNavigate();

    useEffect(() => {
        window.scroll(0, 0);
        anime({
            targets: "#fancy-button",
            scale: [1, 1.1, 1],
            duration: 1000,
            loop: true,
            easing: "easeInOutQuad",
        });
    }, []);

    return (
        <button
            id="fancy-button"
            className="px-4 py-2 btn btn-primary fw-bold shadow-sm"
            style={{ fontSize: '25px' }}
            onClick={() => { navigate('/ai-suggestor') }}
        >
            Try out some AI!
        </button>
    );
};

export const Home = () => {
    const [current, setCurrent] = useState(1)
    const location = useLocation();

    // useEffect(() => {
    // const homeBox = document.querySelector('.home-content');
    // homeBox && homeBox.animate([{ transform: 'scale3d(1, 1, 1)' }, { opacity: '0.75' }],
    //     {
    //         duration: 452.47637475777,
    //         direction: 'reverse',
    //         fill: "forwards"
    //     }
    // ).finished.then((animation) => {
    // homeBox && homeBox.animate([{ transform: 'translate(-1500px)' }, { opacity: '0.25' }],
    //     {
    //         duration: 277,
    //         direction: 'reverse',
    //         fill: "forwards"
    //     })
    // .finished.then(() => {
    //     setCurrent(2);
    //     // })
    // })
    // }, []);

    useEffect(() => {
        const homeBox = document.querySelector('.home-content');
        homeBox && homeBox.animate([{ transform: 'translate3d(1000px, 0, 0)' }, { opacity: '0.25' }],
            {
                duration: 1525.7256,
            })
    }, [current])

    return (
        <div style={{ width: '100%' }}>
            <Helmet>
                <title>Home page - matkhan.net</title>
                <meta property="og:title" content='Home page - matkhan.net' />
                <meta property="og:description" content={`Check out Matheen Mohammed Khan's blog - matkhan.net!`} />
                <meta property="og:image" content='https://matkhan-express-7badb55a96b5.herokuapp.com/mmk-favicon-design.png' />
                <meta property="og:url" content={`https://www.matkhan.net${location.pathname}`} />
                <meta property="og:type" content="article" />
            </Helmet>
            <p id="home-content" class="home-content" style={{
                fontSize: "20px", padding: "40px", marginBottom: '7px', backgroundImage: 'linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)'
            }}>
                <row style={{ flexWrap: 'wrap', textAlign: 'left' }}>
                    <picture>
                        <source media="(max-width:745px)" height="247px" width="100%" srcset={photo} />
                        <source media="(max-width:1000px)" height="294px" width="max-width" srcset={photo} />
                        <img src={photo} alt='' style={{
                            padding: '12.5px',
                            float: 'right',
                            // minHeight: '457px',
                            minHeight: '487px',
                            minWidth: '257px',
                        }}>
                        </img>
                    </picture>
                    <div>
                        <p>
                            Hi! This is <b>Matheen Mohammed Khan</b>. The purpose of the creation of this blog was to spread knowledge and to help create a better world by the understanding of the wisdom that lies behind words, especially when seen from an apriori sense, in what but is the underlying motivation and the effect that stays back after one has read any article, or consumed any content for that matter. It should so be that this knowledge helps.
                        </p>
                        <p>
                            Hopefully I help you in finding either meaning, purpose, inspiration, or motivation with my content! Or some tech use-cases I might have come across, and think are something not present elsewhere, except if by inspiration you work on, which I appreciate in lieue of how much further that idea or use-case is developed in the positive light through well meaning research and experience.
                        </p>
                        <p>
                            <b>Thanks once again, for being here!</b> Right here, right now. Please feel free to share your comments, to like and subscribe and to keep motivated and virtuous in deed shall you be above all in all senses: seeking to help, grow, learn and help again. The beautiful circle of life!
                        </p>
                        <FancyButton />
                    </div>
                </row>
            </p >
            <div className="home-partition"></div>
        </div>)
}
