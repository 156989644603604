import React from "react";
import img1 from '../../assets/mmk-favicon-design.png';

const PrivacyPolicy = () => {
    return (
        <div style={{
            padding: "20px", margin: "auto",
            justifyItems: 'flex-start',
            fontSize: '15px',
            textAlign: 'left'
        }}>
            <img src={img1} alt='' style={{ height: '75px', position: 'absolute', top: '15px', right: '15px', borderRadius: '55px' }}></img>
            <h1>Privacy Policy</h1>
            <p><strong>Effective Date:</strong> [08th March, 2025]</p>

            <h2>1. Introduction</h2>
            <p>Welcome to Matkhan.net. Your privacy is important to us. This Privacy Policy explains how we collect, use, and protect your information when you use our website and services.</p>

            <h2>2. Information We Collect</h2>
            <p>We only collect and store the following data:</p>
            <ul>
                <li><strong>Posts, Comments & Reactions:</strong> When you submit a post, comment or reaction, we store this data in Firebase.</li>
                <li><strong>Authentication Data:</strong> If you sign in using Google Sign-In, Firebase Authentication may collect basic profile information such as your name and email.</li>
            </ul>
            <p>We <strong>do not</strong> collect sensitive personal data such as financial information, precise location, or biometric data.</p>

            <h2>3. How We Use Your Information</h2>
            <p>Your data is used solely to provide and improve our services:</p>
            <ul>
                <li>Displaying posts and comments.</li>
                <li>Managing user-generated content.</li>
                <li>Enabling authentication and access control.</li>
            </ul>

            <h2>4. Data Storage & Security</h2>
            <p>All data is securely stored in <strong>Firebase</strong> (Google Cloud Platform). Firebase provides industry-standard encryption and security measures to protect your data. However, no online service is completely secure, and we encourage you to use strong passwords and protect your credentials.</p>

            <h2>5. Third-Party Services</h2>
            <ul>
                <li>We use Firebase Authentication for login services.</li>
                <li>Google Sign-In is used for authentication, and Google may collect data per their own Privacy Policy.</li>
                <li>We do <strong>not</strong> sell or share your personal data with third parties for advertising purposes.</li>
            </ul>

            <h2>6. Cookies & Tracking Technologies</h2>
            <p>We do <strong>not</strong> use cookies or tracking technologies to monitor your behavior. However, Firebase and Google Authentication may utilize cookies for session management.</p>

            <h2>7. User Rights & Data Deletion</h2>
            {/* <ul> */}
            {/* <li>You can delete your posts and comments at any time within the application.</li> */}
            {/* <li> */}
            <p>If you wish to delete your account or remove associated data, please contact us at [admin@matkhan.net].</p>
            {/* </li> */}
            {/* </ul> */}

            <h2>8. Changes to This Privacy Policy</h2>
            <p>We may update this policy from time to time. Any changes will be posted on this page with an updated effective date.</p>

            <h2>9. Contact Us</h2>
            <p>For any questions regarding this Privacy Policy, please contact us at:</p>
            <p><strong>Email:</strong> [admin@matkhan.net]</p>
            <p><strong>Website:</strong> [matkhan.net]</p>
        </div>
    );
};

export default PrivacyPolicy;


